import AbstractService from "./AbstractService";
import Axios from "axios";

export default class TransferRequestService extends AbstractService {
    private controller = "transfer-requests";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public current(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/current`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public submit(transferToken: string, customerOrderIds: number[]) {
        const d = { transferToken, customerOrderIds };
        const url = this.makeUrl(`${this.controller}/submit`);
        return Axios.post<any>(url, d, this.makeConfig(false));
    }

    public approve(transferRequest: any) {
        const d = { transferRequest };
        const url = this.makeUrl(`${this.controller}/approve`);
        return Axios.put<any>(url, d, this.makeConfig(false));
    }
    
    public cancel(transferRequest: any) {
        const d = { transferRequest };
        const url = this.makeUrl(`${this.controller}/cancel`);
        return Axios.put<any>(url, d, this.makeConfig(false));
    }
}