








































































import { Vue, Component, Prop } from "vue-property-decorator";
import DatePickerInputView from "@/components/shared/DatePickerInputView.vue";

class DateFilterDialogProperties {
    visible: boolean = false;
    title: string = null;
    nullable: boolean = false;
    dateFrom: Date = null;
    dateTo: Date = null;
    dateNull: boolean = false;
    labelDateFrom: string = null;
    labelDateTo: string = null;
    labelDateNull: string = null;
    dateFormat: string = null;
    tag: any = null;
}

export { DateFilterDialogProperties };

@Component({
    components: { DatePickerInputView }
})
export default class DateFilterDialogView extends Vue {
    @Prop({ default: new DateFilterDialogProperties })
    private properties: DateFilterDialogProperties;

    public clearDateFrom() {
        this.properties.dateFrom = null;
    }

    public clearDateTo() {
        this.properties.dateTo = null;
    }

    public submit() {
        if (this.properties.dateNull) {
            this.properties.dateFrom = null;
            this.properties.dateTo = null;
        }

        this.$emit("filtered");
        this.properties.visible = false;
    }

    public close() {
        this.properties.visible = false;
    }
}
