export default class FilterUtil {
	public static booleanOf(s: any) {
		if (s === true || s === 'true') return true;
		if (s === false || s === 'false') return false;
		return null;
    }

	public static dateOf(s: any) {
        const date = new Date(parseInt(s) || "");
        return isNaN(date.getTime()) ? null : date;
	}

    public static filterString(valueNull: boolean, value: string, not: boolean = false) {
        if (valueNull) {
            return "<n>";
        } else if (value != null) {
            if (not) {
                return "<v!>" + value;
            } else {
                return "<v>" + value;
            }
        } else {
            return null;
        }
    }

    public static filterNumber(numberNull: boolean, fromNumber: Number = null, toNumber: Number = null) {
        if (numberNull) {
            return "<n>";
        } else if (fromNumber !== null && toNumber !== null) {
            return `${fromNumber}~${toNumber}`;
        } else if (fromNumber !== null) {
            return ">=" + fromNumber;
        } else if (toNumber !== null) {
            return "<=" + toNumber;
        } else {
            return null;
        }
    }

    public static filterDate(dateNull: boolean, fromDate: Date = null, toDate: Date = null) {
        if (dateNull) {
            return "<n>";
        } else if (fromDate !== null && toDate !== null) {
            return `${fromDate.getTime()}~${toDate.getTime()}`;
        } else if (fromDate !== null) {
            return ">=" + fromDate.getTime();
        } else if (toDate !== null) {
            return "<=" + toDate.getTime();
        } else {
            return null;
        }
    }
}