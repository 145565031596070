import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class ListViewMixin extends Vue {
    protected queueHandle: number = 0;

    public get recordCounts() {
        return [10, 20, 30, 40, 50];
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public load() { }

    public listenVisibleChanged() {
        if ('hidden' in document) {
            document.addEventListener("visibilitychange", this.visibleChanged);
        } else if ('mozHidden' in document) {
            (document as any).addEventListener("mozvisibilitychange", this.visibleChanged);
        } else if ('webkitHidden' in document) {
            (document as any).addEventListener("webkitvisibilitychange", this.visibleChanged);
        } else if ('msHidden' in document) {
            (document as any).addEventListener("msvisibilitychange", this.visibleChanged);
        }
    }

    public removeVisibleChanged() {
        if ('hidden' in document) {
            document.removeEventListener("visibilitychange", this.visibleChanged);
        } else if ('mozHidden' in document) {
            (document as any).removeEventListener("mozvisibilitychange", this.visibleChanged);
        } else if ('webkitHidden' in document) {
            (document as any).removeEventListener("webkitvisibilitychange", this.visibleChanged);
        } else if ('msHidden' in document) {
            (document as any).removeEventListener("msvisibilitychange", this.visibleChanged);
        }
    }

    public visibleChanged(e) {

    }
}