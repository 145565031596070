





























































































































































































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import { DateFilterDialogProperties } from '@/components/shared/DateFilterDialogView.vue';
import ListViewMixin from '@/components/shared/ListViewMixin';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import RouteUtil from '@/utilities/RouteUtil';
import StringUtil from '@/utilities/StringUtil';
import DateUtil from '@/utilities/DateUtil';
import ResponseUtil from '@/utilities/ResponseUtil';
import FilterUtil from '@/utilities/FilterUtil';
import TransferRequestService from "@/services/TransferRequestService";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const DateFilterDialogView = () => import("@/components/shared/DateFilterDialogView.vue");

@Component({
    components: { AlertDialogView, DateFilterDialogView },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            loading: false,
        },
        filter: {
            requestNo: "",
            requestDateFrom: null,
            requestDateTo: null,
            requestByCode: "",
            requestByName: "",
			requestByPsid: "",
			transferToCode: "",
			transferToName: "",
			transferToPsid: "",
            status: "All"
        },
        transferRequests: []
    })
})
export default class TransferRequestListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private dateFilterDialog = new DateFilterDialogProperties();
    private transferRequestService = new TransferRequestService();
    private dateTimeFormat: string = null;
    private dateFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.request-no"),
                value: "uniqueNo",
                width: "9%",
            },
            {
                text: this.$t("text.request-date"),
                value: "formatted.requestDate"
            },
            {
                text: this.$t("text.request-by-code"),
                value: "requestByCode",
                width: "9%"
            },
            {
                text: this.$t("text.request-by-name"),
                value: "formatted.requestByName"
            },
			{
				text: this.$t("text.request-by-psid"),
				value: "requestBy.facebookAccount.pageScopedId",
				width: "9%"
			},
            {
                text: this.$t("text.transfer-to-code"),
                value: "transferToCode",
                width: "9%"
            },
            {
                text: this.$t("text.transfer-to-name"),
                value: "formatted.transferToName"
            },
			{
				text: this.$t("text.transfer-to-psid"),
				value: "transferTo.facebookAccount.pageScopedId",
				width: "9%"
			},
            {
                text: this.$t("text.status"),
                value: "formatted.status",
                width: "150px"
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "80px",
                sortable: false,
            },
        ];
    }

    public statusOptions(withAll: boolean = false) {
        return [
            {
                text: this.$t('text.all'),
                value: "All",
                all: true
            },
            {
                text: this.$t('text.processing'),
                value: "Processing",
                all: false
            },
            {
                text: this.$t('text.transferred'),
                value: "Transferred",
                all: false
            },
            {
                text: this.$t('text.cancelled'),
                value: "Cancelled",
                all: false
            }
        ].filter(x => !x.all || withAll);
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public formatStatus(v: any) {
        return this.statusOptions().find(x => x.value === v).text;
    }

	public requestByUrl(item: any) {
		return `/management/customers/modify?id=${item.requestBy.id}`;
	}

	public transferToUrl(item: any) {
		return `/management/customers/modify?id=${item.transferTo.id}`;
	}

    public created() {
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt(query.pageNo as any ?? 1);
        data.table.recordCount = parseInt(query.recordCount as any ?? 10);

        data.filter.requestNo = query.requestNo ?? "";
        data.filter.requestDateFrom = FilterUtil.dateOf(query.requestDateFrom);
        data.filter.requestDateTo = FilterUtil.dateOf(query.requestDateTo);
        data.filter.requestByCode = query.requestByCode ?? "";
        data.filter.requestByName = query.requestByName ?? "";
        data.filter.transferToCode = query.transferToCode ?? "";
        data.filter.transferToName = query.transferToName ?? "";
        data.filter.status = query.status ?? "All";

        this.load();
    }

    public destroyed() {
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
       const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const status = filter.status === 'All' ? null : filter.status;

            const r = await this.transferRequestService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                requestNo: StringUtil.enclose(filter.requestNo, '%', '%'),
                requestDate: FilterUtil.filterDate(false, filter.requestDateFrom, filter.requestDateTo),
                requestByCode: StringUtil.enclose(filter.requestByCode, '%', '%'),
				requestByPsid: StringUtil.enclose(filter.requestByPsid, '%', '%'),
                transferToCode: StringUtil.enclose(filter.transferToCode, '%', '%'),
				transferToPsid: StringUtil.enclose(filter.transferToPsid, '%', '%'),
                status,
				searchRequestBy: filter.requestByName,
				searchTransferTo: filter.transferToName,
                loadRequestBy: true,
				loadRequestByFacebookAccount: true,
                loadTransferTo: true,
				loadTransferToFacebookAccount: true
            });
            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.dateFormat = ResponseUtil.getDateFormat(r);

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const requests: any[] = r.data.transferRequests;

			requests.forEach(e => {
				const r = e.requestBy;
				const rf = r.facebookAccount;
				const rn = r.name;
				const ru = rf.uniqueNo ?? null;

				const t = e.transferTo;
				const tf = t.facebookAccount;
				const tn = t.name;
				const tu = tf.uniqueNo ?? null;

				e.formatted = {
					requestDate: this.formatDate(e.requestDate),
					requestByName: ru !== null ? `${rn} [${ru}]` : rn,
					transferToName: tu !== null ? `${tn} [${tu}]` : tn,
					status: this.formatStatus(e.status)
				};
			});

            this.$data.transferRequests = requests.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/customer/transfer-requests", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                requestNo: filter.requestNo,
                requestDateFrom: filter.requestDateFrom,
                requestDateTo: filter.requestDateTo,
                requestByCode: filter.requestByCode,
                requestByName: filter.requestByName,
				requestByPsid: filter.requestByPsid,
                transferToCode: filter.transferToCode,
                transferToName: filter.transferToName,
				transferToPsid: filter.transferToPsid,
                status
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public filterRequestDate() {
        const filter = this.$data.filter;
        this.dateFilterDialog.title = this.$t('text.request-date').toString();
        this.dateFilterDialog.dateFrom = filter.requestDateFrom;
        this.dateFilterDialog.dateTo = filter.requestDateTo;
        this.dateFilterDialog.labelDateFrom = this.$t('text.date-from').toString();
        this.dateFilterDialog.labelDateTo = this.$t('text.date-to').toString();
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.tag = 'request-date';
        this.dateFilterDialog.visible = true;
    }

    public async filteredDate() {
        const filter = this.$data.filter;
        if (this.dateFilterDialog.tag === 'request-date') {
            filter.requestDateFrom = this.dateFilterDialog.dateFrom;
            filter.requestDateTo = this.dateFilterDialog.dateTo;
            await this.load();
        }
    }

    public alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
    }
}
